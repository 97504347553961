





































































































































































































































































import Vue from 'vue';
import html2pdf from 'html2pdf.js';
import popUpModal from '@/components/form/pop-up-modal.vue';
import IVertragsfaten from '@/model/IVertragsfaten';
import { PHOTOVOLTAIK_INSURANCE } from '@/constant/constants';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';

export default Vue.extend({
  name: 'photovoltaics-insurance-risk',
  mixins: [dataChangeMixin],
  components: {
    popUpModal,
  },
  data() {
    return {
      name: 'photovoltaics-insurance-risk',
      key: PHOTOVOLTAIK_INSURANCE,
      backUrl: 'photovoltaics-insurance-EEPV-ABPV',
      submitedForm: false,
      modalOpen: false,
      modalTitle: '',
      modalBody: '',
      paramsVertragsdaten: {
        sumInsured: 0,
        sum371: 0,
        paymentMethod: '',
        versicherungssteuer: 0,
        netization: '',
        factorLevel: '',
      },
      schadenverlaufObj: {
        value: 1,
        text: 'Entspricht den marktüblichen Standards',
        reason: '',
      },
      betriebsstundenObj: {
        value: 1,
        text: 'Entspricht den marktüblichen Standards',
        reason: '',
      },
      maintenanceQualitatObj: {
        value: 1,
        text: 'Entspricht den marktüblichen Standards',
        reason: '',
      },
      mengenrabattObj: {
        value: 1,
        text: 'Entspricht den marktüblichen Standards',
        reason: '',
      },
      sonstigesBesichtigungObj: {
        value: 1,
        text: 'Entspricht den marktüblichen Standards',
        reason: '',
      },
      riskItems: [
        {
          value: 1.25,
          text: 'Entspricht nicht den marktüblichen Standards',
        },
        {
          value: 1.1,
          text: 'Entspricht teilweise den marktüblichen Standards',
        },
        {
          value: 1,
          text: 'Entspricht den marktüblichen Standards',
        },
        {
          value: 0.9,
          text: 'Übertrifft teilweise die marktüblichen Standards',
        },
        {
          value: 0.8,
          text: 'Übertrifft die marktüblichen Standards',
        },
      ],
      miscellaneousRiskItems: [
        {
          value: 1.25,
          text: 'Entspricht nicht den marktüblichen Standards',
        },
        {
          value: 1.1,
          text: 'Entspricht teilweise den marktüblichen Standards',
        },
        {
          value: 1,
          text: 'Entspricht den marktüblichen Standards',
        },
        {
          value: 0.9,
          text: '',
        },
        {
          value: 0.8,
          text: 'Übertrifft die marktüblichen Standards',
        },
        {
          value: 0.7,
          text: '',
        },
      ],
      ergebnisDerBewertung: {
        GesamtergebnisGemabRisikofaktorenbewertung: 0,
      },
      pdfStyles: false,
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'id', 'productId'],
  watch: {
    'schadenverlaufObj.value': function (val: any) {
      this.schadenverlaufObj.text = this.getContractRelatedDiscountByValue(val);
    },
    'betriebsstundenObj.value': function (val: any) {
      this.betriebsstundenObj.text = this.getContractRelatedDiscountByValue(val);
    },
    'maintenanceQualitatObj.value': function (val: any) {
      this.maintenanceQualitatObj.text = this.getContractRelatedDiscountByValue(val);
    },
    'mengenrabattObj.value': function (val: any) {
      this.mengenrabattObj.text = this.getContractRelatedDiscountByValue(val);
    },
    'sonstigesBesichtigungObj.value': function (val: any) {
      this.sonstigesBesichtigungObj.text = this.getContractRelatedDiscountByValue(val);
    },
  },
  methods: {
    getContractRelatedDiscountByValue(value: any): string {
      return this.riskItems.find((itm) => itm.value === value)?.text || '';
    },
    goToBackUrl() {
      this.$emit('go-back-tab', this.backUrl, 'insuranceForms', 4);
    },
    paramsVertragsdatenChange(params: IVertragsfaten) {
      this.paramsVertragsdaten.sumInsured = params.sumInsured;
      this.paramsVertragsdaten.sum371 = params.sum371;
      this.paramsVertragsdaten.paymentMethod = params.paymentMethod;
      this.paramsVertragsdaten.versicherungssteuer = params.versicherungssteuer;
      this.paramsVertragsdaten.netization = params.netization;
      this.paramsVertragsdaten.factorLevel = params.factorLevel;
    },
    onSubmit(nextPriceTab = true, changeTabId = true) {
      const data = {
        paramsVertragsdaten: this.paramsVertragsdaten,
        schadenverlaufObj: this.schadenverlaufObj,
        betriebsstundenObj: this.betriebsstundenObj,
        maintenanceQualitatObj: this.maintenanceQualitatObj,
        mengenrabattObj: this.mengenrabattObj,
        sonstigesBesichtigungObj: this.sonstigesBesichtigungObj,
        ergebnisDerBewertung: {
          GesamtergebnisGemabRisikofaktorenbewertung: this.averageRisk,
        },
      };

      this.$emit('risk-factor-data-change', data, nextPriceTab, changeTabId);

      this.submitedForm = true;
    },
    setValues(insuranceData: any) {
      const { riskFactorData } = insuranceData;
      if (riskFactorData) {
        this.paramsVertragsdaten = riskFactorData.paramsVertragsdaten;
        this.schadenverlaufObj = riskFactorData.schadenverlaufObj;
        this.betriebsstundenObj = riskFactorData.betriebsstundenObj;
        this.maintenanceQualitatObj = riskFactorData.maintenanceQualitatObj;
        this.mengenrabattObj = riskFactorData.mengenrabattObj;
        this.sonstigesBesichtigungObj = riskFactorData.sonstigesBesichtigungObj;
        this.ergebnisDerBewertung = riskFactorData.ergebnisDerBewertung;
      }
    },
    openModal(title: string, body: string) {
      this.modalTitle = title;
      this.modalBody = body;
      this.modalOpen = !this.modalOpen;
    },
    modalClose() {
      this.modalTitle = '';
      this.modalBody = '';
      this.modalOpen = false;
    },
    exportToPDF() {
      const filename = `Zusammenfassung-Photovoltaics-Risiko-${this.id}.pdf`;
      this.pdfStyles = true;
      html2pdf(document.getElementById('element-to-convert'), {
        margin: 10,
        filename,
        html2canvas: {
          width: 1500,
        },
      }).then((response) => {
        this.pdfStyles = false;
      });
    },
  },
  computed: {
    averageRisk(): number {
      const value = (this.schadenverlaufObj.value + this.betriebsstundenObj.value + this.maintenanceQualitatObj.value + this.mengenrabattObj.value + this.sonstigesBesichtigungObj.value) / 5;

      return +value.toFixed(2);
    },
    isPCUser(): boolean {
      return this.$store.getters['authModule/isPCUser'];
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    if (this.insuranceData) {
      this.setValues(this.insuranceData);
    }
    (this as any).startWatcherActivated();
  },
  beforeDestroy() {
    if (!this.isPCUser && !this.submitedForm) {
      this.onSubmit(false, false);
    }
  },
});
